import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: 0,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotificationData: (state, action) => {
      state.notifications = action.payload.notifications;
    },

    resetNotificationData: (state, action) => {
      state.notifications = 0;
    },
  },
});

export const { addNotificationData, resetNotificationData } =
  notificationSlice.actions;

export default notificationSlice.reducer;
