import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Row,
  Spinner,
} from 'react-bootstrap';
import {
  IconPencil,
  IconSquareRoundedMinus,
  IconUser,
} from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import * as UserAPI from '../../api/UserAPI';
import * as AuthApi from '../../api/AuthApi';
import * as ComplaintAPI from '../../api/ComplaintAPI';
import { showUpdatedToasterMessage } from '../../store/slices/toaster/toasterslice';
import { addAuthData } from '../../store/slices/authUser/authUserSlice';
import CustomModal from '../../components/CustomModal/CustomModal';
import Utils from '../../components/Utils';
import axios from 'axios';
import { myProfileSchema } from './MyProfileValidation';
import { handleApiError } from '../../common/errorHandler';
import {
  FormField,
  FormFieldCheck,
  FormFieldFullAddress,
  FormFieldSelect,
} from '../../components/Form/FormField';
import { GENDER_OPTIONS, ROLES, SERVICES_TYPE } from '../../common/constants';
const MyProfile = ({ profileModal, setProfileModal }) => {
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.rc.authUserReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [profileImage, setProfileImage] = useState(
    authSelector.profile_image
      ? process.env.REACT_APP_UPLOAD_BASE_URL + authSelector.profile_image
      : '',
  );
  let defaultProfileValue = {
    gender: authSelector.gender,
    first_name: authSelector.first_name,
    last_name: authSelector.last_name,
    email: authSelector.email,
    phone: authSelector.phone,
    profile_image: null,
    address: authSelector.address,
    zipcode: authSelector.zipcode,
    city: authSelector.city,
    province: authSelector.province,
    country: authSelector.country,
    latitude: authSelector.latitude,
    longitude: authSelector.longitude,
  };
  if (authSelector.role === ROLES.COMPANY) {
    defaultProfileValue = {
      ...defaultProfileValue,
      company_name: authSelector.company_name || '',
    };
  }
  if (authSelector.role === ROLES.VENDOR) {
    defaultProfileValue = {
      ...defaultProfileValue,
      about: authSelector.about || '',
      services: authSelector.services || [],
    };
  }
  const {
    register,
    handleSubmit,
    control,
    trigger,
    watch,
    setValue,
    formState: { errors: myProfileError },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(myProfileSchema),
    defaultValues: defaultProfileValue,
  });
  const selectedService = watch('services');
  const fetchCategory = async (propertyId, inputValue) => {
    try {
      const response = await ComplaintAPI.getCategories();
      const options = response?.data?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setCategoryList(options);
    } catch (error) {
      handleApiError(error, dispatch);
    }
  };

  useEffect(() => {
    if (authSelector.role === ROLES.VENDOR) {
      fetchCategory();
      console.log('authSelector.services:', authSelector.services);
      authSelector.services.forEach((service, index) => {
        const { id, name, type, rate } = service;
        setValue(`services_vendor[${id}][id]`, id);
        setValue(`services_vendor[${id}][name]`, name);
        setValue(
          `services_vendor[${id}][type]`,
          SERVICES_TYPE.find((item) => item.value === type),
        );
        setValue(`services_vendor[${id}][rate]`, rate);
      });
    }
  }, []);
  const handleFileInputChange = (e) => {
    let image = e.target.files[0];
    if (image) {
      Utils.getBase64Image(image)
        .then((result) => {
          setProfileImage(result);
        })
        .catch((error) => {
          setValue('profile', null);
        });
    }
  };
  const userFormSubmit = async (formData) => {
    // return false;
    if (authSelector.role === ROLES.VENDOR) {
      formData = {
        ...formData,
        services: formData?.services?.map((item) => item.value) || [],
      };
    }
    if (!formData?.profile) {
      delete formData.profile;
    }
    let refineFormData = Utils.getFormData(formData);
    setIsLoading(true);
    try {
      const { data } = await UserAPI.updateProfile(refineFormData);
      if (data) {
        // updateProfileData(data);
        let {
          gender,
          company_name,
          first_name,
          last_name,
          phone,
          profile_image,
          about,
          services,
        } = data;
        let updatedData = {
          ...authSelector,
          gender: formData.gender,
          first_name: first_name,
          last_name: last_name,
          phone: phone,
          profile_image: profile_image,
          address: formData.address,
          zipcode: formData.zipcode,
          city: formData.city,
          province: formData.province,
          country: formData.country,
          latitude: formData.latitude,
          longitude: formData.longitude,
        };
        if (authSelector.role === ROLES.COMPANY) {
          updatedData = {
            ...updatedData,
            company_name: company_name || formData.company_name,
          };
        }
        if (authSelector.role === ROLES.VENDOR) {
          services = services?.map((item) => ({
            value: item.id,
            label: item.name,
          }));
          updatedData = {
            ...updatedData,
            about: about,
            services: services,
          };
        }
        dispatch(addAuthData(updatedData));
        setProfileModal(false);
        dispatch(
          showUpdatedToasterMessage({
            message: 'Profile saved successfully.',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      handleApiError(error, dispatch);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDeleteProfilePhoto = async () => {
    setIsLoading(true);
    try {
      const { data } = await AuthApi.removeProfileImage();
      if (data) {
        const updatedData = {
          ...authSelector,
          profile_image: '',
        };
        setProfileImage('');
        dispatch(addAuthData(updatedData));
        dispatch(
          showUpdatedToasterMessage({
            message: 'Profile image removed successfully.',
            type: 'success',
          }),
        );
      }
    } catch (error) {
      handleApiError(error, dispatch);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <CustomModal
      show={profileModal}
      isLoading={isLoading}
      onHide={() => setProfileModal(false)}
      modalHeading={'My Profile'}
      size="lg"
      saveBtnText={'Save'}
      onSaveCallback={handleSubmit(userFormSubmit)}
    >
      <Form onSubmit={handleSubmit(userFormSubmit)} autoComplete="off">
        <Row className="row-cards">
          <Form.Group as={Col} className="mb-3">
            <span
              className="avatar avatar-xl bg-gold-lt"
              style={{
                backgroundImage: profileImage
                  ? `url('${profileImage}')`
                  : 'none',
              }}
            >
              {!profileImage && <IconUser stroke={1.5} size={16} />}
            </span>
            <Button
              variant="secondary"
              size="sm"
              // onClick={() => fileInput.current.click()}
              className="btn-icon position-absolute"
              style={{
                zIndex: 1,
                marginLeft: '-29px',
                marginTop: '83px',
              }}
            >
              <IconPencil stroke={1.5} size={16} />
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={handleDeleteProfilePhoto}
              className={`btn-icon position-absolute ${!profileImage ? 'd-none' : ''}`}
              style={{
                zIndex: 1,
                marginLeft: '-106px',
                marginTop: '83px',
              }}
            >
              <IconSquareRoundedMinus stroke={1.5} size={16} />
            </Button>
            <input
              type="file"
              className="-d-none btn-icon position-absolute opacity-0"
              style={{
                zIndex: 1,
                width: '24px',
                marginLeft: '-29px',
                marginTop: '83px',
              }}
              accept="image/png, image/gif, image/jpeg"
              {...register('profile')}
              onChange={handleFileInputChange}
            />
          </Form.Group>
        </Row>
        <Row className="row-cards">
          {authSelector.role === ROLES.COMPANY && (
            <FormField
              label="Company Name"
              name="company_name"
              type="text"
              error={myProfileError?.company_name}
              register={register}
              placeholder="Enter company name"
              autoFocus
              required
            />
          )}
          <FormFieldCheck
            label="Gender"
            name="gender"
            options={GENDER_OPTIONS}
            register={register}
            error={myProfileError?.gender}
            required
            size="12"
          />
          <FormField
            label="First Name"
            name="first_name"
            type="text"
            error={myProfileError?.first_name}
            register={register}
            placeholder="Enter first name"
            autoFocus
            required
          />
          <FormField
            label="Last Name"
            name="last_name"
            type="text"
            error={myProfileError?.last_name}
            register={register}
            placeholder="Enter last name"
            required
          />
          <FormFieldFullAddress
            label="Address"
            name="address"
            error={myProfileError.address}
            register={register}
            control={control}
            setValue={setValue}
            size="12"
            trigger={trigger}
            hasDetails={true}
            required
          />
          <FormField
            label="Email"
            name="email"
            type="text"
            error={myProfileError?.email}
            register={register}
            placeholder="Enter email"
            required
            disabled={true}
          />
          <FormField
            label="Phone"
            name="phone"
            type="number"
            error={myProfileError?.phone}
            register={register}
            placeholder="Enter phone"
            required
          />
          {authSelector.role === ROLES.VENDOR && (
            <Fragment>
              <FormField
                label="About"
                name="about"
                type="text"
                error={myProfileError?.about}
                register={register}
                placeholder="Write here..."
                as="textarea"
                size="12"
              />
              <FormFieldSelect
                label="Services"
                name="services"
                control={control}
                options={categoryList}
                error={myProfileError?.services}
                noOptionsMessage={() => 'No services found!'}
                isSearchable
                isClearable
                isMulti
                required
                size="12"
              />
              {selectedService &&
                selectedService.map((item, index) => {
                  return (
                    <Row key={item.value} className="mt-3">
                      <FormField
                        name={`services_vendor[${item.value}].id`}
                        type="hidden"
                        register={register}
                        size="0"
                        defaultValue={item.value}
                      />
                      <FormField
                        label={index === 0 ? `Service Name` : ``}
                        name={`services_vendor[${item.value}].name`}
                        register={register}
                        size="5"
                        defaultValue={item.label}
                        disabled
                      />
                      <FormFieldSelect
                        label={index === 0 ? `Type` : ``}
                        name={`services_vendor[${item.value}].type`}
                        control={control}
                        defaultValue={SERVICES_TYPE.find(
                          (service_type) => service_type.value === item.type,
                        )}
                        options={SERVICES_TYPE}
                        error={
                          myProfileError?.services_vendor
                            ? myProfileError?.services_vendor[item?.value]?.type
                                ?.value
                            : ''
                        }
                        required={index === 0 ? true : false}
                        size="3"
                      />
                      <FormField
                        label={index === 0 ? `Rate` : ``}
                        name={`services_vendor[${item.value}].rate`}
                        type="number"
                        error={
                          myProfileError?.services_vendor
                            ? myProfileError?.services_vendor[item?.value]?.rate
                            : ''
                        }
                        register={register}
                        placeholder="Enter rate"
                        required={index === 0 ? true : false}
                        size="4"
                      />
                    </Row>
                  );
                })}
            </Fragment>
          )}
        </Row>
      </Form>
    </CustomModal>
  );
};
export default MyProfile;
