import React from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { LANDING_REDIRECT_URL } from '../common/constants';
const SiteFooter = ({ isAuthenticate, isSticky = false }) => {
  const renderContent = () => {
    return (
      <div
        className={`text-center mt-4 ${isSticky ? 'fixed-bottom mb-2' : ''}`}
      >
        <Link
          target="_blank"
          to={LANDING_REDIRECT_URL.TERMS}
          className="text-dark fw-bold"
        >
          Terms & Conditions
        </Link>
        <span target="_blank" className="mx-2 fw-bold">
          |
        </span>
        <Link to={LANDING_REDIRECT_URL.PRIVACY} className="text-dark fw-bold">
          Privacy Policy
        </Link>
        <p>
          &copy; {new Date().getFullYear()} {process.env.REACT_APP_NAME}
        </p>
      </div>
    );
  };
  if (isAuthenticate) {
    return renderContent();
  }
  return (
    <footer className="footer footer-transparent d-print-none py-0">
      <Container fluid="xl">{renderContent()}</Container>
    </footer>
  );
};
export default SiteFooter;
