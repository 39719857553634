import React from 'react';
import { Col, Row } from 'react-bootstrap';

const Disclaimer = () => {
  return (
    <Row className="g-1 mt-3">
      <Col className="col-auto strong">Disclaimer:</Col>
      <Col className="text-muted">
        In case the emails are not received in 2 minute, please check your{' '}
        <strong>spam/junk</strong> folder and marked as safe.
      </Col>
    </Row>
  );
};
export default Disclaimer;
