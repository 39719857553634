import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Verify from '../page/Authentication/Verify';
import { COMMON_ROUTE } from '../common/constants';

const TenancyRegistration = lazy(() => import('../page/TenancyRegistration'));

// import CmsSettings from "../page/AccountSettings/CmsSettings";
// import ColorManagement from "../page/AccountSettings/ColorManagement";
// import Notifications from "../page/AccountSettings/Notifications";

const Login = lazy(() => import('../page/Authentication/Login'));
const Signup = lazy(() => import('../page/Authentication/Signup'));
const ForgotPassword = lazy(
  () => import('../page/Authentication/ForgotPassword'),
);
const ResetPassword = lazy(
  () => import('../page/Authentication/ResetPassword'),
);
const MobileUser = lazy(() => import('../page/Authentication/MobileUser'));

const ProtectedRoute = lazy(() => import('./ProtectedRoute'));

const Dashboard = lazy(() => import('../page/Dashboard'));
const Company = lazy(() => import('../page/Company/Company'));
const Owner = lazy(() => import('../page/Owner/Owner'));
const Tenant = lazy(() => import('../page/Tenant/Tenant'));
const TenancyApplications = lazy(
  () => import('../page/TenancyApplications/TenancyApplications'),
);
const Tenancies = lazy(() => import('../page/Tenancies/Tenancies'));
const Manager = lazy(() => import('../page/Manager/Manager'));
const Building = lazy(() => import('../page/Building/Building'));
const Publish = lazy(() => import('../page/Building/Publish/Publish'));

const Complaints = lazy(() => import('../page/Complaints/Complaints'));
const Tasks = lazy(() => import('../page/Tasks/Tasks'));
const Rent = lazy(() => import('../page/Transactions/rent'));
const Task = lazy(() => import('../page/Transactions/task'));
const Maintenance = lazy(() => import('../page/Transactions/maintenance'));
const Subscriptions = lazy(() => import('../page/Transactions/subscriptions'));
const Disbursements = lazy(() => import('../page/Disbursement/Disbursements'));
const Vendors = lazy(() => import('../page/Vendors/Vendors'));
const Guest = lazy(() => import('../page/Guest/Guest'));
const Feedbacks = lazy(() => import('../page/Feedbacks/Feedbacks'));
const RolesAndPermission = lazy(
  () => import('../page/RolesAndPermission/RolesAndPermission'),
);
const Advertisement = lazy(
  () => import('../page/Advertisements/Advertisement'),
);

const Billing = lazy(() => import('../page/Billing/Billing'));
const PlanExpired = lazy(() => import('../page/Billing/PlanExpired'));
const ComingSoon = lazy(() => import('../components/ComingSoon'));

const Logout = lazy(() => import('../page/Authentication/Logout'));
const ErrorPage = lazy(() => import('../page/ErrorPage'));

const unauthorizedRoutes = [
  {
    path: '/',
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/signup',
        element: <Signup />,
      },
      {
        path: 'logout',
        element: <Logout />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: '/reset-password/:token',
        element: <ResetPassword />,
      },
      {
        path: '/verify/:token',
        element: <Verify />,
      },
      {
        path: '/mobile-user',
        element: <MobileUser />,
      },
      {
        path: '/tenancy-registration',
        element: <TenancyRegistration />,
      },
      ...COMMON_ROUTE,
    ],
  },
  {
    path: '*',
    element: <Navigate to="/login" replace={true} />,
  },
];
const getRoles = (role, permissions, auth) => {
  let parsedPermissions = permissions ? JSON.parse(atob(permissions)) : [];
  parsedPermissions = parsedPermissions.flatMap((permission) => {
    if (permission.slug === 'tenancy') {
      // Create a new permission object with slug "tenancies"
      const newPermission = {
        ...permission,
        name: 'Tenancies',
        slug: 'tenancies',
      };
      return [permission, newPermission];
    }
    return permission;
  });
  // const parsedPermissionsSet = new Set(parsedPermissions.map((module) => module.slug));

  const arr = [
    {
      path: '/',
      element: <ProtectedRoute redirectPath="/login" auth={auth} />,
      children: [
        {
          index: true,
          element: (
            <Dashboard
              permission={
                parsedPermissions.find(
                  (permission) => permission.slug === 'dashboard',
                ) || {}
              }
            />
          ),
        },
        {
          path: 'logout',
          element: <Logout />,
        },
        ...COMMON_ROUTE,
      ],
    },
    { path: '*', element: <ErrorPage /> },
  ];

  const moduleMappings = {
    dashboard: <Dashboard />,
    companies: <Company />,
    buildings: <Building />,
    managers: <Manager />,
    owners: <Owner />,
    tenants: <Tenant />,
    tenancy: <TenancyApplications />,
    tenancies: <Tenancies />,
    complaints: <Complaints />,
    tasks: <Tasks />,
    transactions: () => ({
      rent: <Rent />,
      task: <Task />,
      subscriptions: <Subscriptions />,
      maintenance: <Maintenance />,
    }),
    disbursements: <Disbursements />,
    vendors: <Vendors />,
    guests: <Guest />,
    feedbacks: <Feedbacks />,
    'roles-and-permissions': <RolesAndPermission />,
  };

  const { children } = arr[0];

  parsedPermissions
    .filter((module) => module.read && moduleMappings[module.slug])
    .forEach((module) => {
      const Components = moduleMappings[module.slug];

      if (typeof Components === 'function') {
        const nestedRoutes = Components();
        children.push({
          path: module.slug,
          children: Object.entries(nestedRoutes).map(([path, element]) => ({
            path,
            element: React.cloneElement(element, {
              permissions: module,
              role,
            }),
          })),
        });
      } else {
        children.push({
          path: module.slug,
          element: React.cloneElement(moduleMappings[module.slug], {
            permissions: module,
            role,
          }),
        });
      }
      children.push(
        {
          path: '/buildings/publish/:id',
          element: <Publish />,
        },
        {
          path: 'advertisement',
          element: <Advertisement />,
        },
        {
          path: 'plan-details',
          element: <Billing />,
        },
        {
          path: 'plan-expired',
          element: <PlanExpired />,
        },
      );
    });

  return arr;
};

export const Routes = (role, permissions, auth) => {
  return role === 'undefined'
    ? unauthorizedRoutes
    : getRoles(role, permissions, auth);
};
