import axios from 'axios';
const API_PREFIX = process.env.REACT_APP_API_WEB_PREFIX;
const headers = {
  headers: { 'Content-Type': 'multipart/form-data' },
};

export const create = async (payload) => {
  return await axios
    .post(`auth/register`, payload, headers)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getProfile = async (id) => {
  return await axios
    .get(`${API_PREFIX}users/get/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const updateProfile = async (payload) => {
  return await axios
    .put(`${API_PREFIX}users/update`, payload, headers)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const updatePassword = async (payload) => {
  return await axios
    .put(`${API_PREFIX}users/set-password/`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const removeAccount = async () => {
  return await axios
    .put(`${API_PREFIX}users/delete-profile`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
