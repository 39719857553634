import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: 0,
  gender: '',
  company_name: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  profile_image: '',
  about: '',
  services: [],
  rate: '',
  auth: false,
  role: '',
  roles: '',
  token: '',
  fcm_token: '',
  permissions: '',
  is_trial_plan: false,
  is_active_plan: false,
  plan_end_date: '',
  currentPlan: {},
  address: '',
  zipcode: '',
  city: '',
  province: '',
  country: '',
  latitude: '',
  longitude: '',
};

export const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    addAuthData: (state, action) => {
      state.id = action.payload.id;
      state.gender = action.payload.gender;
      state.company_name = action.payload.company_name;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.profile_image = action.payload.profile_image;
      state.about = action.payload.about;
      state.services = action.payload.services;
      state.rate = action.payload.rate;
      state.auth = action.payload.auth;
      state.role = action.payload.role;
      state.roles = action.payload.roles;
      state.token = action.payload.token;
      state.fcm_token = action.payload.fcm_token;
      state.permissions = action.payload.permissions;
      state.is_trial_plan = action.payload.is_trial_plan;
      state.is_active_plan = action.payload.is_active_plan;
      state.plan_end_date = action.payload.plan_end_date;
      state.currentPlan = action.payload.currentPlan;
      state.address = action.payload.address;
      state.zipcode = action.payload.zipcode;
      state.city = action.payload.city;
      state.province = action.payload.province;
      state.country = action.payload.country;
      state.latitude = action.payload.latitude;
      state.longitude = action.payload.longitude;
    },

    resetAuthData: (state, action) => {
      state.id = 0;
      state.gender = '';
      state.company_name = '';
      state.first_name = '';
      state.last_name = '';
      state.email = '';
      state.phone = '';
      state.profile_image = '';
      state.about = '';
      state.services = [];
      state.rate = '';
      state.auth = false;
      state.role = '';
      state.roles = '';
      state.token = '';
      state.fcm_token = '';
      state.permissions = '';
      state.is_trial_plan = false;
      state.is_active_plan = false;
      state.plan_end_date = '';
      state.currentPlan = {};
      state.address = '';
      state.city = '';
      state.province = '';
      state.country = '';
      state.zipcode = '';
      state.latitude = '';
      state.longitude = '';
    },
  },
});

export const { addAuthData, resetAuthData } = authUserSlice.actions;

export default authUserSlice.reducer;
