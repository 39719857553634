import * as Yup from 'yup';
import {
  ALPHABETIC_REQUIRED_FIELD,
  EMAIL_FIELD,
  PHONE_FIELD,
  REQUIRED_FIELD,
} from '../../common/validation';
export const myProfileSchema = Yup.object().shape({
  gender: Yup.string().nullable().required('This field is required!'),
  first_name: ALPHABETIC_REQUIRED_FIELD,
  last_name: ALPHABETIC_REQUIRED_FIELD,
  address: REQUIRED_FIELD,
  email: EMAIL_FIELD,
  phone: PHONE_FIELD,
  photos: Yup.mixed().test(
    'fileType',
    'Only image file are allowed',
    function (value) {
      if (!value || value.length === 0) return true; // Skip validation if no file is provided
      const supportedFormats = ['jpg', 'jpeg', 'png', 'gif'];
      const fileName = value[0].name.toLowerCase();
      const fileExtension = fileName.split('.').pop();
      return value && supportedFormats.includes(fileExtension);
    },
  ),
});
