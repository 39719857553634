import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { NAV_ITEMS } from '../common/constants';

const useRole = () => {
  const [roles, setRoles] = useState('');

  const authSelector = useSelector((state) => state.rc.authUserReducer);

  useEffect(() => {
    if (authSelector.permissions) {
      setRoles(authSelector.permissions || '');
    }
  }, [authSelector.permissions]);

  const getRoles = useMemo(() => {
    if (roles?.length > 0) {
      const parsedRoles = JSON.parse(atob(roles));
      return NAV_ITEMS.filter((item) =>
        parsedRoles.some(
          (secondItem) => secondItem.slug === item.slug && secondItem.read,
        ),
      );
    } else {
      return NAV_ITEMS;
    }
  }, [roles]);

  return getRoles;
};

export default useRole;
