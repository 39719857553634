import moment from 'moment';
import 'moment-timezone';
class Utils {
  static haveSameData = (obj1, obj2) => {
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;

    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key],
      );
    }
    return false;
  };
  static getAcronym = (name) => {
    if (!name || typeof name.trim !== 'function' || name.trim().length === 0) {
      return ''; // Return a default value when the name is empty
    }

    const filteredName = name.split(' ').filter((item) => item.trim() !== '');
    let res = '';

    if (filteredName.length < 2) {
      res = (filteredName[0][0] || '') + (filteredName[0][1] || '');
    } else {
      let i = 0;
      filteredName.forEach((item) => {
        const [char] = item;
        i++;
        if (i < 3) {
          res += char;
        }
      });
    }

    return res.toUpperCase();
  };
  static priceFormat = (price, includeDecimals = true) => {
    if (isNaN(price)) {
      return '$0';
    }
    const dollarUS = Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      // currencyDisplay: "code",
      minimumFractionDigits: includeDecimals ? 2 : 0,
    });
    return dollarUS.format(price);
  };
  static phoneFormat = (phoneNumber) => {
    if (!phoneNumber || phoneNumber === null || phoneNumber == 'null') {
      return null;
    }
    const formattedPhoneNumber =
      phoneNumber.length === 10
        ? `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
        : `+${phoneNumber.slice(0, 1)} ${phoneNumber.slice(1, 4)}-${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`;
    return formattedPhoneNumber;
  };
  static dateFormat(date, format = 'YYYY-MM-DD') {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      return null;
    }

    const formattedDate = momentDate.format(format);
    return formattedDate;
  }
  static ucFirst(str) {
    if (typeof str !== 'string' || str.trim() === '') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  static getBase64Image = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  // static getFormData = (object, isDocuments = false) => {
  //     const formData = new FormData();
  //     for (const key in object) {
  //         const value = object[key];
  //         if (Array.isArray(value)) {
  //             value.forEach((item) => formData.append(`${key}${!isDocuments ? "[]" : ""}`, item));
  //         } else if (value instanceof FileList) {
  //             formData.append(key, value[0]);
  //         } else {
  //             formData.append(key, value);
  //         }
  //     }
  //     return formData;
  // };
  static getFormData = (object, isDocuments = false, mediaFields = []) => {
    const formData = new FormData();
    for (const key in object) {
      const value = object[key];
      if (Array.isArray(value)) {
        // value.forEach((item) => formData.append(`${key}${!isDocuments ? "[]" : ""}`, item));
        if (isDocuments && mediaFields.includes(key)) {
          value.forEach((item) => formData.append(key, item));
        } else {
          value.forEach((item) => formData.append(`${key}[]`, item));
        }
      } else if (value instanceof FileList) {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  };
  static getTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  };
  static combineDateTime(date, time) {
    const dateMoment = moment(date).format('YYYY-MM-DD');
    return `${dateMoment} ${time}`;
  }
  static localToUTC(localDate) {
    const timeZone = this.getTimeZone();
    return moment.tz(localDate, timeZone).utc().format();
  }
  static utcToLocal(utcDate) {
    const timeZone = this.getTimeZone();
    return moment.utc(utcDate).tz(timeZone).format();
  }
  static daysBetween(givenDate) {
    const todayDate = moment().startOf('day');
    const endDate = moment(givenDate).startOf('day');
    const dayDifference = endDate.diff(todayDate, 'days');
    return dayDifference;
  }
}
export default Utils;
