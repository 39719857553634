import { House, More, Profile2User } from 'iconsax-react';
import { Dropdown } from 'react-bootstrap';
import Utils from '../components/Utils';
import {
  IconEye,
  IconFileDollar,
  IconMailForward,
  IconPencil,
  IconPlus,
  IconTrash,
  IconUser,
  IconX,
} from '@tabler/icons-react';
import { IconCheck } from '@tabler/icons-react';
export const Thumbnail = ({ url, title, size = 'xs', className = '' }) => {
  return (
    <span
      className={`avatar avatar-${size} bg-primary-lt me-2 ${!url && size === 'xs' ? 'fs-5' : ''} ${
        !url && ' border border-primary'
      } ${className}`}
      style={{
        backgroundImage: url ? `url(${url})` : 'none',
      }}
    >
      {!url &&
        (title?.trim() ? (
          Utils.getAcronym(title)
        ) : (
          <IconUser className="icon avatar-icon" />
        ))}
    </span>
  );
};
export const Status = ({ value, isBadge = true, message = '' }) => {
  let badgeClass = isBadge ? 'badge badge-outline text-' : 'fw-bold text-';
  let label = '';

  switch (value) {
    case 'submitted':
      badgeClass += 'primary';
      label = 'Submitted';
      break;
    case 'rescheduled':
      badgeClass += 'primary';
      label = 'Rescheduled';
      break;
    case 'started':
      badgeClass += 'primary';
      label = 'Started';
      break;
    case 'paused':
      badgeClass += 'yellow';
      label = 'Paused';
      break;
    case 'resumed':
      badgeClass += 'cyan';
      label = 'Resumed';
      break;
    case 'approved':
      badgeClass += 'green';
      label = 'Approved';
      break;
    case 'pending':
      badgeClass += 'warning';
      label = 'Pending';
      break;
    case 'waiting':
      badgeClass += 'primary';
      label = 'Waiting';
      break;
    case 'in-process':
    case 'in_process':
      badgeClass += 'info';
      label = 'In Process';
      break;
    case 'payment_in_process':
      badgeClass += 'info';
      label = 'Payment In Process';
      break;
    case 'on-hold':
    case 'on_hold':
      badgeClass += 'warning';
      label = 'On Hold';
      break;
    case 'resolved':
      badgeClass += 'green';
      label = 'Resolved';
      break;
    case 'paid':
      badgeClass += 'green';
      label = 'Paid';
      break;
    case 'rejected':
      badgeClass += 'danger';
      label = 'Rejected';
      break;
    case 'cancelled':
      badgeClass += 'danger';
      label = 'Cancelled';
      break;
    default:
      badgeClass += 'secondary';
      label = 'Unknown';
      break;
  }

  return <span className={badgeClass}>{message === '' ? label : message}</span>;
};
export const Priority = ({ value }) => {
  let color;
  const status = value.charAt(0).toUpperCase() + value.slice(1);
  switch (value) {
    case 'low':
      color = 'grey';
      break;
    case 'medium':
      color = 'primary';
      break;
    case 'high':
      color = 'orange';
      break;
    case 'severe':
      color = 'red';
      break;
    default:
      return null;
  }
  return <span className={`text-${color}`}>{status}</span>;
};
export const Actions = ({
  permissions = {},
  row,
  onAddUnit = false,
  onEdit = false,
  onVerification = false,
  onDelete = false,
  onApprove = false,
  onReject = false,
  onAssignManager = false,
  onAssignBuilding = false,
  onView = false,
  onDisbursement = false,
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={'span'} bsPrefix={'cursor-pointer'}>
        <More size={20} className="sw-1" style={{ rotate: '90deg' }} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dd-action">
        {onView && permissions.read && (
          <Dropdown.Item
            onClick={() => {
              onView(row);
            }}
            className={`${row?.original?.status === 'pending' ? 'd-none' : ''}`}
          >
            <IconEye className="icon dropdown-item-icon me-2" /> View
          </Dropdown.Item>
        )}
        {onAddUnit &&
          permissions.update &&
          row?.original?.subtype?.has_units && (
            <Dropdown.Item
              onClick={() => {
                onAddUnit(row);
              }}
            >
              <IconPlus className="icon dropdown-item-icon me-2" /> View or Add
              Unit
            </Dropdown.Item>
          )}
        {onEdit && permissions.update && (
          <Dropdown.Item
            onClick={() => {
              onEdit(row);
            }}
            className={`${
              row?.original?.terms_of_tenancy &&
              (row?.original?.status === 'rejected' ||
                row?.original?.status === 'approved')
                ? 'disabled'
                : ''
            }`}
          >
            <IconPencil className="icon dropdown-item-icon me-2" /> Edit
          </Dropdown.Item>
        )}
        {onVerification && permissions.update && (
          <Dropdown.Item
            onClick={() => {
              onVerification(row);
            }}
            className={`${row?.original?.verified ? 'd-none' : ''}`}
          >
            {' '}
            <IconMailForward className="icon dropdown-item-icon me-2" />{' '}
            Re-Verification
          </Dropdown.Item>
        )}
        {onDelete && permissions.delete && (
          <Dropdown.Item
            className={`${row?.original?.is_paid && row?.original?.is_paid === true ? 'd-none' : ''}`}
            onClick={() => {
              onDelete(row);
            }}
          >
            <IconTrash className={`icon dropdown-item-icon me-2`} /> Delete
          </Dropdown.Item>
        )}
        {onAssignManager && row?.original?.pmc !== null && (
          <Dropdown.Item
            onClick={() => {
              onAssignManager(row);
            }}
          >
            <Profile2User className="icon dropdown-item-icon me-2" /> Assign
            Manager
          </Dropdown.Item>
        )}
        {onAssignBuilding && (
          <Dropdown.Item
            onClick={() => {
              onAssignBuilding(row);
            }}
          >
            <House className="icon dropdown-item-icon me-2" /> Assign Buildings
          </Dropdown.Item>
        )}
        {onApprove && permissions.update && (
          <Dropdown.Item
            onClick={() => {
              onApprove(row);
            }}
            className={`${row?.original?.status === 'pending' || row?.original?.status === 'approved' ? 'disabled' : ''}`}
          >
            <IconCheck className="icon dropdown-item-icon me-2" /> Approve
          </Dropdown.Item>
        )}
        {onReject && permissions.update && (
          <Dropdown.Item
            onClick={() => {
              onReject(row);
            }}
            className={`${
              row?.original?.status === 'pending' ||
              row?.original?.status === 'rejected' ||
              row?.original?.status === 'approved'
                ? 'disabled'
                : ''
            }`}
          >
            <IconX className="icon dropdown-item-icon me-2" /> Reject
          </Dropdown.Item>
        )}
        {onDisbursement && (
          <Dropdown.Item
            onClick={() => {
              onDisbursement(row);
            }}
            className={`${row?.original?.is_paid ? 'd-none' : ''}`}
          >
            <IconFileDollar className="icon dropdown-item-icon me-2" /> Mark as
            Disbursed
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
