import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ACCOUNT_DROPDOWN_ITEMS, ROLES } from '../common/constants';
import Utils from '../components/Utils';
import MyProfile from '../page/MyProfile/MyProfile';
const HeaderDropdown = ({ isMobile = false }) => {
  const authSelector = useSelector((state) => state.rc.authUserReducer);
  const [profileModal, setProfileModal] = useState(false);
  const userName =
    authSelector.role === ROLES.COMPANY
      ? authSelector.company_name
      : `${authSelector.first_name} ${authSelector.last_name}`;
  return (
    <Fragment>
      <Dropdown className="nav-link cursor-pointer">
        <Dropdown.Toggle
          as={'a'}
          bsPrefix={`nav-link d-flex lh-1 text-reset p-0 text-dark ${!isMobile ? 'dropdown-toggle' : ''}`}
        >
          <span
            className={`avatar  avatar-rounded bg-primary-lt ${isMobile ? 'fs-3' : 'avatar-sm fs-4 me-2'}`}
            style={{
              backgroundImage: authSelector.profile_image
                ? `url('${process.env.REACT_APP_UPLOAD_BASE_URL}${authSelector.profile_image}')`
                : 'none',
            }}
          >
            {!authSelector.profile_image && Utils.getAcronym(userName)}
          </span>
          <div className="d-none d-xl-block ps-2">
            <div>{userName}</div>
            <div className="mt-1 small text-muted">{authSelector.role}</div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          className="dropdown-menu-end dropdown-menu-arrow"
        >
          {ACCOUNT_DROPDOWN_ITEMS.map((item, i) => {
            if (
              item.value === 'Plan Details' &&
              (authSelector?.role === ROLES.SUPERADMIN ||
                authSelector?.role === ROLES.MANAGER)
            )
              return '';
            if (item.value === 'My Profile') {
              return (
                <Dropdown.Item
                  key={`dd-${i}`}
                  onClick={() => {
                    setProfileModal(true);
                  }}
                >
                  {item.icon}
                  {item.value}
                </Dropdown.Item>
              );
            }
            return (
              <Dropdown.Item key={`dd-${i}`} as={NavLink} to={item.to}>
                {item.icon}
                {item.value}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
      {profileModal && (
        <MyProfile
          profileModal={profileModal}
          setProfileModal={setProfileModal}
        />
      )}
    </Fragment>
  );
};
export default HeaderDropdown;
