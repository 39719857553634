import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Card,
  Col,
  Dropdown,
  ListGroup,
  Row,
  Spinner,
  Toast,
  ToastContainer,
} from 'react-bootstrap';
import { IconBell } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';
import * as NotificationAPI from '../api/NotificationAPI';
import { NOTIFICATIONS_TYPES, TASK_STATUS } from '../common/constants';
import { requestPermission, onMessageListener } from '../common/firebase';
import { useInView } from 'react-intersection-observer';
import { toast } from 'react-toastify';
import { addAuthData } from '../store/slices/authUser/authUserSlice';
import { replace } from 'lodash';
const NotificationsDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authSelector = useSelector((state) => state.rc.authUserReducer);
  const notificationData = useSelector((state) => state.rc.notificationReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [totalNotificationCount, setTotalNotificationCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [loadingRef, inView] = useInView();
  /**
   * Firebase Web Push Notification
   */
  const [notification, setNotification] = useState({ title: '', body: '' });
  useEffect(() => {
    // Check if service worker is supported by the browser
    if ('serviceWorker' in navigator) {
      // Register the service worker
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js') // URL to your service worker file
        .then((registration) => {
          // console.log("Service Worker registered successfully.", registration);
        })
        .catch((error) => {
          console.error('Error registering Service Worker.', error);
        });
    } else {
      console.log('Service Worker is not supported in this browser.');
    }
    requestPermission().then((fcmToken) => {
      dispatch(addAuthData({ ...authSelector, fcm_token: fcmToken }));
    });
  }, []);
  onMessageListener().then((payload) => {
    setNotification({
      title: payload?.notification?.title,
      body: payload?.notification?.body,
    });

    // toast notification
    const renderNotification = (payload) => {
      const { title, body } = payload.notification;
      return (
        <Fragment>
          <h3 className="text-body mb-0">{title}</h3>
          <div>{body}</div>
        </Fragment>
      );
    };
    toast(renderNotification(payload));
    // fetchNotifications();
    console.log('Fetch notifications...');
    setNotificationCount(notificationCount + 1);
  });
  /**
   * End Web Push Notification
   */
  const [filter, setFilter] = useState({
    _page: 1,
    _limit: 10,
    _sort: 'id',
    _order: 'desc',
  });
  const fetchNotifications = async () => {
    setIsLoading(true);

    try {
      await NotificationAPI.getAll(filter).then(({ data }) => {
        if (data) {
          setTotalNotificationCount(data.count);
          setNotifications([...notifications, ...data?.data]);
        }
      });
    } catch (error) {
      // handleApiError(error, dispatch);
    } finally {
      setIsLoading(false);
    }
  };
  const renderNoNotifications = () => {
    return (
      <ListGroup.Item>
        <Row className="justify-content-center">
          <Col className="text-center text-muted">No notification found.</Col>
        </Row>
      </ListGroup.Item>
    );
  };
  const updateNotification = async (id = false) => {
    setIsLoading(true);
    try {
      if (id) {
        await NotificationAPI.markRead(id);
        // console.log("updateNotification:",notifica)
        // setNotificationCount(notification-1);
      } else {
        await NotificationAPI.markReadAll();
        // setNotificationCount(0);
      }
    } catch (error) {
      // handleApiError(error, dispatch);
    } finally {
      setIsLoading(false);
    }
  };
  const handleRead = (event, item) => {
    event.preventDefault();
    const { id, type, read_by } = item;
    if (NOTIFICATIONS_TYPES.TASK_STATUS_CHANGE === type) {
      navigate(
        `/tasks?code=${item?.metadata?.task_code?.replace(/#/g, '')}&type=view`,
      );
    } else if (NOTIFICATIONS_TYPES.INVOICE_RAISED === type) {
      navigate(
        `/tasks?code=${item?.metadata?.task_code?.replace(/#/g, '')}&type=invoice`,
      );
    } else if (NOTIFICATIONS_TYPES.COMPLAINT_STATUS_CHANGE === type) {
      navigate(
        `/complaints?code=${item?.metadata?.complaint_code?.replace(/#/g, '')}&type=view`,
      );
    }
    const updatedNotifications = notifications.map((notification) => {
      if (notification.id === id) {
        return {
          ...notification,
          read_by: [...notification.read_by, authSelector.id],
        };
      }
      return notification;
    });
    setNotifications(updatedNotifications);
    if (read_by && read_by.length === 0) {
      updateNotification(id);
    }
  };
  const handleReadAll = (event) => {
    event.preventDefault();
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      read_by: [...notification.read_by, authSelector.id],
    }));
    setNotifications(updatedNotifications);
    updateNotification();
  };
  useEffect(() => {
    fetchNotifications();
  }, [filter]);
  useEffect(() => {
    if (notificationData && notificationData.notifications) {
      // setNotificationCount(notificationData.notifications);
    }
    const countReadNotifications = notifications.filter(
      (notification) => notification.read_by.length === 0,
    ).length;
    setNotificationCount(countReadNotifications);
  }, [notifications]);
  useEffect(() => {
    if (inView && totalNotificationCount > notifications.length) {
      setFilter((prevState) => ({
        ...prevState,
        _page: prevState._page + 1,
      }));
    }
  }, [inView]);
  const getNotificationTitle = (item) => {
    const { type, title, metadata } = item;
    let refinedTitle;
    switch (type) {
      case NOTIFICATIONS_TYPES.ADD_NEW_COMPLAINT:
      case NOTIFICATIONS_TYPES.COMPLAINT_STATUS_CHANGE:
        refinedTitle = `${metadata?.complaint_code || ''}`;
        break;
      case NOTIFICATIONS_TYPES.TASK_ASSIGNED:
        refinedTitle = `${metadata?.task_details?.task_code || ''}`;
        break;
      case NOTIFICATIONS_TYPES.TASK_STATUS_CHANGE:
        refinedTitle = `${metadata?.task_code || ''}`;
        break;
      default:
        refinedTitle = `${title || ''}`;
    }
    return refinedTitle;
  };
  return (
    <Fragment>
      <Dropdown className="nav-item -d-md-flex me-3">
        <Dropdown.Toggle
          as="a"
          className="nav-link cursor-pointer px-0 dropdown-arrow-none"
        >
          <IconBell size={24} stroke={1.1} className="" />
          {notificationCount !== 0 && (
            <Badge
              pill
              bg="danger"
              className={`badge-animated ${notificationCount > 10 ? 'px-1' : ''}`}
            >
              {notificationCount}
            </Badge>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          className="dropdown-menu-arrow dropdown-menu-card"
        >
          <Card>
            <Card.Header>
              <Card.Title as={'h3'}>Notifications</Card.Title>
              {notifications.length > 0 && (
                <Link
                  to={''}
                  onClick={handleReadAll}
                  className="ms-auto text-muted small"
                >
                  Mark all as read
                </Link>
              )}
            </Card.Header>
            <ListGroup variant="flush" className="list-group-hoverable scroll">
              {!isLoading && notifications.length === 0
                ? renderNoNotifications()
                : ''}
              {notifications?.map((item, i) => {
                const { body, read_by, metadata } = item;

                return (
                  <ListGroup.Item key={`notification-${i}`}>
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <span className="status-dot d-block"></span>
                      </Col>
                      <Col
                        className={`text-truncate ${read_by && read_by.length === 0 ? 'strong' : ''}`}
                      >
                        <Link
                          to={''}
                          className="text-body"
                          onClick={(e) => handleRead(e, item)}
                        >
                          {getNotificationTitle(item)}
                        </Link>
                        <div className="d-block text-muted text-truncate mt-n1">
                          {body}
                        </div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                );
              })}
              {isLoading ? (
                <div className="text-center my-3">
                  <Spinner variant="primary" />
                </div>
              ) : (
                <div ref={loadingRef}></div>
              )}
            </ListGroup>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};
export default NotificationsDropdown;
