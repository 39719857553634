import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { IconSwitch3 } from '@tabler/icons-react';
import * as AuthAPI from '../api/AuthApi';
import { handleApiError } from '../common/errorHandler';
import { addAuthData } from '../store/slices/authUser/authUserSlice';
import { ROLES } from '../common/constants';
import { useNavigate } from 'react-router-dom';
import { showUpdatedToasterMessage } from '../store/slices/toaster/toasterslice';
const ChangeRoleDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.rc.authUserReducer);
  const [roles, setRoles] = useState(authSelector.roles);
  const [isLoading, setIsLoading] = useState(false);
  const [stateUpdated, setStateUpdated] = useState(false);
  const handleRoleChange = async (roleId) => {
    setIsLoading(true);

    try {
      await AuthAPI.switchRole({ roleId }).then(({ data }) => {
        if (data) {
          let {
            user: {
              id,
              gender,
              company_name,
              first_name,
              last_name,
              email,
              phone,
              profile_image,
              about,
              services,
              rate,
            },
            roles,
            permissions,
            token,
            is_trial_plan,
            is_active_plan,
            plan_end_date,
            currentPlan,
          } = data;
          const allowedRoles = [
            ROLES.SUPERADMIN,
            ROLES.COMPANY,
            ROLES.MANAGER,
            ROLES.OWNER,
          ];
          const filteredRoles = roles.filter((role) =>
            allowedRoles.includes(role.role_slug),
          );
          const refinePermissions = permissions.map(({ matrix, ...rest }) => ({
            ...rest,
            ...matrix,
          }));
          const currentRole = roles.find((item) => item.isCurrentRole === true);
          const role = currentRole ? currentRole.role_slug : undefined;
          if (
            role === ROLES.SUPERADMIN ||
            role === ROLES.COMPANY ||
            role === ROLES.MANAGER ||
            role === ROLES.OWNER
          ) {
            if (role === ROLES.VENDOR) {
              services = services?.map((item) => ({
                ...item,
                value: item.id,
                label: item.name,
              }));
            }
            dispatch(
              addAuthData({
                id: id,
                gender: gender,
                company_name: company_name || '',
                first_name: first_name || '',
                last_name: last_name || '',
                email: email || '',
                phone: phone || '',
                profile_image: profile_image || '',
                auth: true,
                role: role,
                roles: filteredRoles,
                token: token,
                about: about || '',
                services: services || [],
                rate: rate || '',
                permissions: btoa(JSON.stringify(refinePermissions)),
                is_active_plan,
                is_trial_plan,
                plan_end_date,
                currentPlan,
              }),
            );
            setStateUpdated(true);
          }
        }
      });
    } catch (error) {
      handleApiError(error, dispatch);
    } finally {
      setIsLoading(false);
      window.location.href = '/';
    }
  };
  if (roles.length < 2) {
    return;
  }
  return (
    <Fragment>
      <Dropdown className="nav-link cursor-pointer">
        <Dropdown.Toggle
          as={'a'}
          bsPrefix={`nav-link d-flex lh-1 text-reset p-0 text-dark dropdown-toggle`}
        >
          <IconSwitch3 className="icon me-1" /> Switch role
        </Dropdown.Toggle>
        <Dropdown.Menu
          align="end"
          className="dropdown-menu-end dropdown-menu-arrow"
        >
          {roles.map((item, i) => {
            if (
              item.role_slug === ROLES.SUPERADMIN ||
              item.role_slug === ROLES.COMPANY ||
              item.role_slug === ROLES.MANAGER ||
              item.role_slug === ROLES.OWNER
            ) {
              return (
                <Dropdown.Item
                  key={`dd-${i}`}
                  onClick={() => handleRoleChange(item.role_id)}
                  disabled={item.isCurrentRole}
                >
                  {item.role_name}
                </Dropdown.Item>
              );
            }
            return;
          })}
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  );
};

export default ChangeRoleDropdown;
