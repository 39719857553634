import axios from 'axios';
const API_PREFIX = process.env.REACT_APP_API_AUTH_PREFIX;
export const login = async (payload) => {
  return await axios
    .post(`${API_PREFIX}auth/web-login`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const register = async (payload) => {
  return await axios
    .post(`${API_PREFIX}auth/register`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const forgotPassword = async (payload) => {
  return await axios
    .post(`${API_PREFIX}auth/forgot-password`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const verify = async (token) => {
  return await axios
    .get(`${API_PREFIX}auth/verify/${token}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const generateVerificationToken = async (token) => {
  return await axios
    .post(`${API_PREFIX}auth/verification/${token}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const resetPassword = async (token, payload) => {
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return await axios
    .put(`${API_PREFIX}auth/reset-password`, payload, headers)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const removeProfileImage = async () => {
  return await axios
    .delete(`${API_PREFIX}auth/profile/delete`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const switchRole = async (payload) => {
  return await axios
    .post(`${API_PREFIX}auth/switchRole`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const logout = async () => {
  return await axios
    .post(`${API_PREFIX}auth/logout`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
