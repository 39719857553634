import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import SiteFooter from '../../interface/Footer';
import AnimatedComponent from '../Animation/AnimatedComponent';
import { LANDING_REDIRECT_URL } from '../../common/constants';
function AuthenticationBasic(props) {
  const { isSticky = false, isTight = false } = props;
  const typeValue = new URLSearchParams(useLocation().search).get('type');
  const renderRedirectUrl = () => {
    if (typeValue === 'pmc') {
      return LANDING_REDIRECT_URL.LANDING_PMC;
    } else if (typeValue === 'vendor') {
      return LANDING_REDIRECT_URL.LANDING_VENDOR;
    } else {
      return LANDING_REDIRECT_URL.LANDING;
    }
  };
  return (
    <AnimatedComponent
      className="page page-center animate-fade"
      animationClass="in"
    >
      <div
        className={`container container-tight py-4 ${isTight ? 'container-signup' : ''}`}
      >
        <div className={`text-center mb-4 ${isSticky ? 'fixed-top mt-4' : ''}`}>
          <Link
            to={renderRedirectUrl()}
            className="navbar-brand navbar-brand-autodark"
          >
            <img src={Logo} height={'50'} alt="" />
          </Link>
        </div>
        {props.children}
        <SiteFooter isAuthenticate isSticky={isSticky} />
      </div>
    </AnimatedComponent>
  );
}
export default AuthenticationBasic;
