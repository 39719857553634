// errorHandler.js
// import { showUpdatedToasterMessage } from './yourToasterMessageModule';

import { showUpdatedToasterMessage } from '../store/slices/toaster/toasterslice';

export const handleApiError = (error, dispatch, setError = false) => {
  if (typeof error?.message === 'string') {
    dispatch(
      showUpdatedToasterMessage({
        message: error.message,
        type: 'danger',
      }),
    );
  } else if (typeof error?.message === 'object') {
    console.log('elseif::');
    const keys = Object.keys(error.message);
    if (keys.length > 0) {
      if (setError) {
        setError(keys[0], {
          type: 'custom',
          message: error.message[keys[0]].message,
        });
      } else {
        dispatch(
          showUpdatedToasterMessage({
            message: error.message[keys[0]].message,
            type: 'danger',
          }),
        );
      }
    }
  } else {
    console.error(error);
  }
};
