import React, { Fragment, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, NavItem, NavDropdown } from 'react-bootstrap';

import Logo from '../assets/images/logo.svg';
import useRole from '../hooks/useRole';
import MyProfile from '../page/MyProfile/MyProfile';
import HeaderDropdown from './HeaderDropdown';
import { LogoutCurve } from 'iconsax-react';
import NotificationsDropdown from './NotificationsDropdown';
import useIsMobile from '../hooks/useIsMobile';
import { IconBadgeAd } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import { ROLES } from '../common/constants';

const Sidebar = () => {
  const authSelector = useSelector((state) => state.rc.authUserReducer);
  const location = useLocation();
  const currentUrl = location.pathname;
  const isMobile = useIsMobile();
  const navItems = useRole();

  const [profileModal, setProfileModal] = useState(false);
  const [isOpen, setIsOpen] = useState({
    show: false,
    place: '',
  });
  const handleToggle = (isOpen, meta, item) => {
    const hasItems = currentUrl.includes(item);
    setIsOpen({
      show: hasItems ? true : isOpen,
      place: item,
    });
  };

  const handleSelect = (item) => {
    setTimeout(() => {
      setIsOpen({
        show: true,
        place: item,
      });
    }, 0);
  };
  if (
    authSelector.role === ROLES.COMPANY ||
    authSelector.role === ROLES.OWNER
  ) {
    if (!authSelector?.is_active_plan) return;
  }
  const SubMenu = ({ item }) => {
    return (
      <NavDropdown
        title={
          <Fragment>
            <span className="nav-link-icon d-md-none d-lg-inline-block">
              {item.icon}
            </span>
            <span className="nav-link-title">{item.name}</span>
          </Fragment>
        }
        id={`topnav-dropdown-${item.name}`}
        className={`${isOpen.place === item.slug && isOpen.show ? 'bg-muted-lt text-dark' : ''}`}
        show={isOpen.place === item.slug && isOpen.show}
        onToggle={(...args) => handleToggle(...args, item.slug)}
        onSelect={() => handleSelect(item.slug)}
      >
        <div className="dropdown-menu-columns">
          <div className="dropdown-menu-column">
            {item.submenu.map((subItem, j) => (
              <NavDropdown.Item
                key={`submenu-${subItem.name}`}
                as={NavLink}
                to={subItem.to}
              >
                {subItem.name}
              </NavDropdown.Item>
            ))}
          </div>
        </div>
      </NavDropdown>
    );
  };

  const renderNav = () => {
    return (
      <Nav as="ul" className="pt-lg-3">
        {navItems.map((item, i) => (
          <NavItem as="li" key={`topnav-${item.name}`} className="">
            {item.submenu ? (
              <SubMenu item={item} />
            ) : (
              <Nav.Link as={NavLink} to={item.to}>
                <span className="nav-link-icon">{item.icon}</span>
                <span className="nav-link-title">{item.name}</span>
              </Nav.Link>
            )}
          </NavItem>
        ))}
        {authSelector.role === ROLES.SUPERADMIN && (
          <NavItem as="li" className="">
            <Nav.Link as={NavLink} to={'/advertisement'}>
              <span className="nav-link-icon">
                <IconBadgeAd className="icon" />
              </span>
              <span className="nav-link-title">Advertisements</span>
            </Nav.Link>
          </NavItem>
        )}
      </Nav>
    );
  };

  return (
    <Navbar
      as={'aside'}
      collapseOnSelect
      expand="lg"
      className="shadow navbar-vertical	sticky-top"
    >
      <Container fluid>
        <Navbar.Toggle />
        <Navbar.Brand as={'h1'} className="justify-content-md-start ps-md-3">
          <Link to={'/'}>
            <img src={Logo} className="navbar-brand-image" alt="Logo" />
          </Link>
        </Navbar.Brand>
        <Nav as={'div'} className="flex-row d-lg-none">
          {isMobile && <NotificationsDropdown />}
          <HeaderDropdown isMobile />
        </Nav>
        <Navbar.Collapse>
          {renderNav()}
          <Nav as="ul" bsPrefix="nav py-2" navbar={false}>
            <NavItem as="li" className="">
              <NavLink to={'/logout'} className="nav-link text-red">
                <span className="nav-link-icon text-red">
                  <LogoutCurve style={{ rotate: '180deg' }} />
                </span>
                <span className="nav-link-title">Logout</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
        {profileModal && (
          <MyProfile
            profileModal={profileModal}
            setProfileModal={setProfileModal}
          />
        )}
      </Container>
    </Navbar>
  );
};
export default Sidebar;
