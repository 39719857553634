import React, { Fragment, useEffect, useState } from 'react';
import AuthenticationBasic from '../../components/Authentication/AuthenticationBasic';
import { Helmet } from 'react-helmet';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { SiteWrapper } from '../../interface/SiteWrapper';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as AuthAPI from '../../api/AuthApi';
import { handleApiError } from '../../common/errorHandler';
import { useDispatch } from 'react-redux';
import { IconAlertTriangle, IconCircleCheck } from '@tabler/icons-react';
import Loader from '../../components/Loader';
import AnimatedComponent from '../../components/Animation/AnimatedComponent';
import Disclaimer from './Disclaimer';
import { ROLES } from '../../common/constants';
import PlayStore from '../../assets/images/mobile/play-store.png';
import AppStore from '../../assets/images/mobile/app-store.png';
const Verify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { token } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingVerificationToken, setIsLoadingVerificationToken] =
    useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isAllowedSignIn, setIsAllowedSignIn] = useState(true);
  const [message, setMessage] = useState('');
  const verifyToken = async () => {
    setIsLoading(true);
    try {
      await AuthAPI.verify(token).then(({ data }) => {
        // const formattedRes = res.message;
        const role = data?.slug;
        if (data?.verified === true) {
          setIsVerified(true);
          setMessage('Verified successfully.');
        }
        if (
          role === ROLES.SUPERADMIN ||
          role === ROLES.COMPANY ||
          role === ROLES.MANAGER ||
          role === ROLES.OWNER
        ) {
          setIsAllowedSignIn(true);
        } else {
          setIsAllowedSignIn(false);
        }
      });
    } catch (error) {
      setIsVerified(false);
      setMessage('This link may expire or invalid!');
    } finally {
      setIsLoading(false);
    }
  };
  const generateVerificationToken = async () => {
    setIsLoadingVerificationToken(true);
    try {
      await AuthAPI.generateVerificationToken(token).then(({ data }) => {
        // const formattedRes = res.message;
        // if (data?.verified === true) {
        setIsVerified(true);
        setMessage('Verification link sent successfully.');
        // }
      });
    } catch (error) {
      setIsVerified(false);
      setMessage('Invalid Token');
    } finally {
      setIsLoadingVerificationToken(false);
    }
  };
  const openDeepLink = () => {
    const userAgent = navigator.userAgent;

    // Check for Android
    if (/Android/i.test(userAgent)) {
      window.location.href = 'http://recoapp.com';
    }
    // Check for iOS
    else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href = 'recoapp://';
    }
    // Assume desktop for other platforms
    else {
      navigate('/login');
    }
  };
  useEffect(() => {
    verifyToken();
  }, []);
  return (
    <AuthenticationBasic isSticky>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} - Verify</title>
      </Helmet>
      <Container>
        <Card className="fade-in">
          <div
            className={`card-status-top bg-${isLoading ? 'dark-lt' : isVerified ? 'success' : 'danger'}`}
          ></div>
          <Card.Body className={`py-4 text-center ${isLoading ? 'py-5' : ''}`}>
            {isLoading ? (
              <h1 className="text-primary">
                Loading<span className="animated-dots"></span>
              </h1>
            ) : (
              <div className="fade-in">
                {isVerified && (
                  <IconCircleCheck className="icon icon-lg text-green mb-2" />
                )}
                {!isVerified && (
                  <IconAlertTriangle className="icon icon-lg text-danger mb-2" />
                )}
                <h3 className={`text-${isVerified ? 'success' : 'danger'}`}>
                  {message}
                </h3>
                {isVerified &&
                  (isAllowedSignIn === false ? (
                    <Fragment>
                      <p>
                        Thank you for connecting with us. Please use links below
                        to download our mobile apps and login to access the
                        system.
                      </p>
                      <Row className="justify-content-center">
                        <Col className="col-auto">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.residentconnect.recoapp"
                            title="Android"
                          >
                            <img
                              src={PlayStore}
                              className="img-fluid"
                              alt="Android"
                            />
                          </a>
                        </Col>
                        <Col className="col-auto">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://apps.apple.com/us/app/reco-by-resident-connect/id6450892720"
                            title="Apple Store"
                          >
                            <img
                              src={AppStore}
                              className="img-fluid"
                              alt="Apple Store"
                            />
                          </a>
                        </Col>
                      </Row>
                    </Fragment>
                  ) : (
                    <div className="text-center mt-3">
                      <button className="fw-bold btn " onClick={openDeepLink}>
                        Go to Sign In
                      </button>
                    </div>
                  ))}
                {!isVerified && (
                  <div className="text-center mt-3">
                    <button
                      className="fw-bold btn btn-primary"
                      onClick={generateVerificationToken}
                      disabled={isLoadingVerificationToken}
                    >
                      {isLoadingVerificationToken && (
                        <Spinner size="sm" className="me-1" />
                      )}
                      Send verification email
                    </button>
                  </div>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
        <Disclaimer />
      </Container>
    </AuthenticationBasic>
  );
};
export default Verify;
