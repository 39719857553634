import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyDHBtZQIW9rNA24ejIOYGTfmtBUmOFMflM',
  authDomain: 'residentconnect-bb07f.firebaseapp.com',
  projectId: 'residentconnect-bb07f',
  storageBucket: 'residentconnect-bb07f.appspot.com',
  messagingSenderId: '111258596060',
  appId: '1:111258596060:web:5d65257b6673858469fa19',
};
const firebaseNotificationKeyPair =
  'BNHKHZ0kPOYM_ES7w4cBiCUhGLC3QNER9Mv2wpR3-5iOObAXLX_rafqdJuoGE_opbBTwY7yKPgIFJqkkh6-YKg0';
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = () => {
  // console.log("Requesting User Permission......");
  return Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      // console.log("Notification User Permission Granted.");
      return getToken(messaging, {
        vapidKey: firebaseNotificationKeyPair,
      })
        .then((currentToken) => {
          if (currentToken) {
            // console.log("Client Token: ", currentToken);
            return currentToken;
          } else {
            console.log('Failed to generate the app registration token.');
          }
        })
        .catch((err) => {
          console.log(
            'An error occurred when requesting to receive the token.',
            err,
          );
        });
    } else {
      console.log('User Permission Denied.');
    }
  });
};

// requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log("Received notification...")
      resolve(payload);
    });
  });
