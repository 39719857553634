import axios from 'axios';
const API_PREFIX = process.env.REACT_APP_API_WEB_PREFIX;
const headers = {
  headers: { 'Content-Type': 'multipart/form-data' },
};
export const getAll = async (param, extra = '') => {
  let filter =
    '?' +
    extra +
    (param
      ? Object.keys(param)
          .filter((key) => param[key] !== '')
          .map(function (key) {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(param[key])
            );
          })
          .join('&')
      : '');
  return await axios
    .get(`${API_PREFIX}complaints/get${filter}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getOne = async (id) => {
  return await axios
    .get(`${API_PREFIX}complaints/get/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const create = async (payload) => {
  return await axios
    .post(`${API_PREFIX}complaints/add`, payload, headers)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const update = async (payload) => {
  return await axios
    .put(`${API_PREFIX}complaints/change-status`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const remove = async (id) => {
  return await axios
    .put(`${API_PREFIX}complaints/delete/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const assignVendor = async (payload) => {
  return await axios
    .put(`${API_PREFIX}complaints/assign-vendor`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getCategories = async () => {
  return await axios
    .get(`${API_PREFIX}categories/get-all`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getParentCategories = async () => {
  return await axios
    .get(`${API_PREFIX}categories/parent/get-all`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getAllSubCategories = async () => {
  return await axios
    .get(`${API_PREFIX}categories/sub/get-all`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
export const getSubCategoriesById = async (categoryId) => {
  return await axios
    .get(`${API_PREFIX}categories/one/get/${categoryId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => Promise.reject(error?.response.data));
};
