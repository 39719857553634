import { useEffect, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import SelectBox from '../SelectBox';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { IconExternalLink, IconEye, IconEyeOff } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
export const FormField = ({
  label,
  name,
  type,
  register,
  error = '',
  placeholder = '',
  size = '6',
  hint = '',
  autoFocus,
  required,
  downloadLink = false,
  downloadLinkLeft = false,
  isDecimal = true,
  isLabel = true,
  xs = 12,
  ...props
}) => {
  useEffect(() => {
    const handleWheel = (e) => {
      if (e.target.type === 'number') {
        e.preventDefault();
      }
    };
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);
  const handleKeyDown = (e) => {
    // Check if the key pressed is "e" or "E"
    if (type === 'number' && (e.key === 'e' || e.key === 'E')) {
      e.preventDefault();
    }
    if (type === 'number' && !isDecimal && e.key === '.') {
      e.preventDefault();
    }
    if (type === 'number' && isDecimal && e.key.match(/[0-9]/)) {
      const decimalIndex = e.target.value.indexOf('.');
      if (
        decimalIndex !== -1 &&
        e.target.value.slice(decimalIndex + 1).length >= 2
      ) {
        e.preventDefault();
      }
    }
  };

  return (
    <Form.Group as={Col} lg={size} xs={xs}>
      {isLabel && (
        <Form.Label className={`${required ? 'required' : ''}`}>
          {label}{' '}
          <Form.Text className="form-label-description" muted>
            {hint}
          </Form.Text>
        </Form.Label>
      )}
      <Form.Control
        type={type}
        onKeyDown={handleKeyDown}
        isInvalid={error ? true : false}
        placeholder={placeholder}
        autoFocus={autoFocus}
        {...register(name)}
        {...props}
      />
      {downloadLink && (
        <Form.Text
          as="div"
          className={`text-truncate text-break text-${downloadLinkLeft ? 'start' : 'end'}`}
        >
          {downloadLink}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">
        {error.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
export const FormFieldAddress = ({
  label,
  name,
  type,
  control,
  setValue,
  register,
  error = '',
  placeholder = 'Enter address',
  size = '6',
  hint = '',
  autoFocus,
  required,
  ...props
}) => (
  <Form.Group as={Col} md={size}>
    <Form.Label className={`${required ? 'required' : ''}`}>
      {label}{' '}
      <Form.Text className="form-label-description" muted>
        {hint}
      </Form.Text>
    </Form.Label>
    <Controller
      name={name} // Specify the field name
      control={control} // Pass the control object from useForm
      render={({ field }) => (
        <ReactGoogleAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY}
          options={{
            fields: ['formatted_address', 'geometry', 'icon', 'name'],
            strictBounds: false,
            types: ['geocode'],
          }}
          onPlaceSelected={(place) => {
            setValue(name, place.formatted_address);
          }}
          {...field}
          {...props}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          placeholder={placeholder}
        />
      )}
    />
    <Form.Control.Feedback type="invalid">
      {error.message}
    </Form.Control.Feedback>
  </Form.Group>
);
export const FormFieldFullAddress = ({
  label,
  name,
  type,
  control,
  setValue,
  register,
  trigger = () => {},
  error = '',
  placeholder = 'Enter address',
  size = '6',
  hint = '',
  autoFocus,
  required,
  hasDetails = false,
  ...props
}) => (
  <Form.Group as={Col} lg={size}>
    <Form.Label className={`${required ? 'required' : ''}`}>
      {label}{' '}
      <Form.Text className="form-label-description" muted>
        {hint}
      </Form.Text>
    </Form.Label>
    <Controller
      name={name} // Specify the field name
      control={control} // Pass the control object from useForm
      render={({ field }) => (
        <ReactGoogleAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_AUTOCOMPLETE_API_KEY}
          options={{
            strictBounds: false,
            types: ['address'],
            componentRestrictions: { country: ['ca', 'us'] },
          }}
          onPlaceSelected={(place) => {
            setValue(name, place?.formatted_address || place?.name);
            const addressComponents = place?.address_components;
            console.log('🚀 ~ addressComponents:', addressComponents);
            const addressDetails = {
              formatted_address: place?.formatted_address,
              latitude: place.geometry?.location.lat(),
              longitude: place.geometry?.location.lng(),
            };

            // Loop through the address components
            addressComponents?.forEach((component) => {
              const types = component?.types;
              if (types.includes('postal_code')) {
                addressDetails.postal_code = component?.long_name;
              } else if (types.includes('country')) {
                addressDetails.country = component?.short_name;
              } else if (types.includes('administrative_area_level_1')) {
                addressDetails.state_province = component?.long_name;
              } else if (types.includes('locality')) {
                addressDetails.city = component?.long_name;
              } // Add more conditions for other address components if needed
            });

            if (hasDetails) {
              setValue('latitude', addressDetails?.latitude || '');
              setValue('longitude', addressDetails?.longitude || '');
              setValue('zipcode', addressDetails?.postal_code || '');
              setValue('city', addressDetails?.city || '');
              setValue('country', addressDetails?.country || '');
              setValue('province', addressDetails?.state_province || '');
              // Trigger validation
              trigger('country');
              trigger('province');
              trigger('city');
              trigger('zipcode');
            }
          }}
          {...field}
          {...props}
          className={`form-control ${error ? 'is-invalid' : ''}`}
          placeholder={placeholder}
        />
      )}
    />
    <Form.Control.Feedback type="invalid">
      {error.message}
    </Form.Control.Feedback>
  </Form.Group>
);
export const FormFieldPassword = ({
  label,
  name,
  type,
  register,
  error = '',
  placeholder = '',
  size = '6',
  hint = '',
  autoFocus,
  required,
  downloadLink = false,
  ...props
}) => {
  const [isShow, setIsShow] = useState(true);
  return (
    <Form.Group as={Col} md={size}>
      <Form.Label className={`${required ? 'required' : ''}`}>
        {label}{' '}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <InputGroup className={`input-group-flat ${error ? 'is-invalid' : ''}`}>
        <Form.Control
          type={`${isShow ? 'password' : 'text'}`}
          isInvalid={error ? true : false}
          placeholder={placeholder}
          {...register(name)}
          autoFocus={autoFocus}
          {...props}
        />
        <InputGroup.Text as="span" className={`${error && 'border-danger'}`}>
          <Link
            to={''}
            tabIndex="-1"
            className={`${error && 'text-danger'}`}
            onClick={() => setIsShow(!isShow)}
          >
            {isShow ? (
              <IconEye stroke={1.5} size={20} />
            ) : (
              <IconEyeOff stroke={1.5} size={20} />
            )}
          </Link>
        </InputGroup.Text>
      </InputGroup>
      {downloadLink && (
        <Form.Text as="div" className="text-truncate text-break text-end">
          {downloadLink}
        </Form.Text>
      )}
      <Form.Control.Feedback type="invalid">
        {error.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
export const FormFieldCheck = ({
  size = '6',
  label,
  name,
  type = 'radio',
  options,
  register,
  error = '',
  inline = true,
  required,
  className = '',
  checkClass = '',
  checkBoxClass = '',
  isLabel = true,
  isShowError = true,
}) => {
  return (
    <Form.Group as={Col} md={size} className={className}>
      {isLabel && (
        <Form.Label className={`${required && 'required'}`}>{label}</Form.Label>
      )}
      {options.map((option) => (
        <Form.Check
          inline={inline}
          key={option.value || option.label}
          type={type}
          className={`mt-2 ${checkClass} ${error ? 'is-invalid' : ''}`}
        >
          <Form.Check.Input
            type={type}
            {...register(name)}
            className={checkBoxClass}
            value={option.value}
            isInvalid={error ? true : false}
            id={option.value}
          />
          <Form.Check.Label htmlFor={option.value}>
            {option.label}{' '}
            {option.document && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_UPLOAD_BASE_URL}${option.document}`}
                className="ms-2 d-inline-block text-truncate text-break position-absolute"
                style={{ maxWidth: 'calc(100% - 50%)' }}
              >
                <IconExternalLink className="icon me-1" />
                {option.document_name}
              </a>
            )}
          </Form.Check.Label>
        </Form.Check>
      ))}
      {isShowError && (
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
export const FormFieldSelectGroup = ({
  size = '12',
  label,
  name,
  type = 'radio',
  options,
  register,
  error = '',
  inline = true,
  required,
  className = '',
  checkClass = '',
  checkBoxClass = 'form-selectgroup-input',
  isLabel = true,
  isShowError = true,
  isDisabled = false,
  labelClass = '',
  commonLabelClass = '',
  xs = '12',
  ...props
}) => {
  return (
    <Form.Group as={Col} lg={size} xs={xs} className={className}>
      {isLabel && (
        <Form.Label className={`${required && 'required'}`}>{label}</Form.Label>
      )}
      <div className="form-selectgroup">
        {options.map((option, idx) => {
          return (
            <label key={idx} className="form-selectgroup-item">
              <Form.Check.Input
                bsPrefix={checkBoxClass}
                type={type}
                {...register(name)}
                value={option.value}
                isInvalid={error ? true : false}
                id={option.value}
                {...props}
                disabled={option?.disabled}
              />
              <span
                className={`form-selectgroup-label rc-input ${commonLabelClass} ${error ? 'border-danger text-danger' : ''} ${isDisabled && option.disabled ? 'bg-muted-lt' : labelClass}`}
              >
                {option.label}
              </span>
            </label>
          );
        })}
      </div>
      {isShowError && (
        <Form.Control.Feedback type="invalid- " className="text-danger mt-1">
          {error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
export const FormFieldSelect = ({
  size = '6',
  label,
  name,
  placeholder = 'Select',
  control,
  options = [],
  error = '',
  isClearable = false,
  isSearchable = false,
  required,
  isLabel = true,
  isTransparent = false,
  xs = undefined,
  ...props
}) => {
  return (
    <Col bsPrefix={`col-${size}`} xs={xs}>
      <Form.Group>
        {isLabel && (
          <Form.Label className={`${required ? 'required' : ''}`}>
            {label}
          </Form.Label>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <SelectBox
              {...field}
              isClearable={isClearable}
              isSearchable={isSearchable}
              options={options}
              className={`p-0 border-0 ${!isTransparent ? 'form-control' : 'transparent-select'} ${error ? 'is-invalid' : ''}`}
              placeholder={placeholder}
              isTransparent={isTransparent}
              {...props}
            />
          )}
        />
        <Form.Control.Feedback type="invalid">
          {error.message}
        </Form.Control.Feedback>
      </Form.Group>
    </Col>
  );
};
export const FormFieldDatePicker = ({
  label,
  name,
  control,
  error = '',
  placeholder = '',
  size = '6',
  hint = '',
  autoFocus,
  required,
  ...props
}) => {
  const fieldName = name; // Store the field name as a separate variable

  return (
    <Form.Group as={Col} md={size}>
      <Form.Label className={`${required ? 'required' : ''}`}>
        {label}{' '}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            wrapperClassName="w-100"
            selected={field.value}
            onChange={(date) => field.onChange(date)}
            className={`form-control ${error ? 'is-invalid' : ''}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            dateFormat="yyyy-MM-dd"
            fixedHeight
            {...props}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  );
};
export const FormFieldRangeDatePicker = ({
  label,
  name,
  control,
  error = '',
  placeholder = '',
  size = '6',
  hint = '',
  autoFocus,
  required,
  ...props
}) => {
  const fieldName = name; // Store the field name as a separate variable

  return (
    <Form.Group as={Col} md={size}>
      <Form.Label className={`${required ? 'required' : ''}`}>
        {label}{' '}
        <Form.Text className="form-label-description" muted>
          {hint}
        </Form.Text>
      </Form.Label>
      <Controller
        control={control}
        name={fieldName}
        defaultValue={null}
        render={({ field }) => (
          <ReactDatePicker
            wrapperClassName="w-100"
            selected={field.value}
            // onChange={(date) => field.onChange(date)}
            className={`form-control ${error ? 'is-invalid' : ''}`}
            placeholderText={placeholder}
            autoFocus={autoFocus}
            dateFormat="yyyy-MM-dd"
            fixedHeight
            {...props}
          />
        )}
      />
      {error && <Form.Text className="text-danger">{error.message}</Form.Text>}
    </Form.Group>
  );
};
