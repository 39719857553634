import React, { Suspense, useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';

import Loader from '../components/Loader';
import useLogin from '../hooks/useLogin';
import SiteFooter from '../interface/Footer';
import SiteHeader from '../interface/Header';

import { Page, PageWrapper } from '../interface/SiteWrapper';
import Sidebar from '../interface/Sidebar';
import AnimatedComponent from '../components/Animation/AnimatedComponent';
import { ROLES } from '../common/constants';

const InjectProtectedNode = ({ authSelector }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const getRoles = useLogin(
    authSelector.auth,
    authSelector.role,
    authSelector.permissions,
  );
  const urlParams = new URLSearchParams(window.location.search);
  const redirectStatus = urlParams.get('redirect_status');
  useEffect(() => {
    if (location?.pathname !== '/logout') {
      if (
        authSelector.role === ROLES.COMPANY ||
        authSelector.role === ROLES.OWNER
      ) {
        if (!authSelector?.is_active_plan && redirectStatus !== 'succeeded') {
          navigate('/plan-expired');
        }
      }
    }
  }, [location?.pathname]);
  return (
    <AnimatedComponent className="page animate-fade" animationClass="in">
      <Sidebar />
      <SiteHeader />
      <PageWrapper>
        <Suspense fallback={<Loader top="64px" height="calc(100% - 64px)" />}>
          {useRoutes(getRoles)}
        </Suspense>
        <SiteFooter />
      </PageWrapper>
    </AnimatedComponent>
  );
};

export default InjectProtectedNode;
