import { combineReducers } from 'redux';
import toasterReducer from './slices/toaster/toasterslice';
import authUserReducer from './slices/authUser/authUserSlice';
import notificationReducer from './slices/notification/notificationSlice';

export default combineReducers({
  toasterReducer,
  authUserReducer,
  notificationReducer,
});
