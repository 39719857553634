import React, { useCallback, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Nav,
  Navbar,
  Dropdown,
  NavItem,
  Button,
  NavbarBrand,
} from 'react-bootstrap';
import { IconBell, IconSearch } from '@tabler/icons-react';

import { ACCOUNT_DROPDOWN_ITEMS, ROLES } from '../common/constants';

import useRole from '../hooks/useRole';
import MyProfile from '../page/MyProfile/MyProfile';
import HeaderDropdown from './HeaderDropdown';
import NotificationsDropdown from './NotificationsDropdown';
import useIsMobile from '../hooks/useIsMobile';
import ChangeRoleDropdown from './ChangeRoleDropdown';
import Utils from '../components/Utils';
import Logo from '../assets/images/logo.svg';
import { addAuthData } from '../store/slices/authUser/authUserSlice';
import moment from 'moment';
const SiteHeader = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navItems = useRole();
  const authSelector = useSelector((state) => state.rc.authUserReducer);
  const [profileModal, setProfileModal] = useState(false);
  const remainingDays = authSelector?.plan_end_date
    ? Utils.daysBetween(authSelector?.plan_end_date)
    : authSelector?.currentPlan?.end_date
      ? Utils.daysBetween(authSelector?.currentPlan?.end_date)
      : 0;
  const renderSearch = useCallback(() => {
    return (
      <div>
        <form autoComplete="off" noValidate="">
          <div className="input-icon">
            <span className="input-icon-addon">
              <IconSearch className="icon" />
            </span>
            <input type="text" className="form-control" placeholder="Search…" />
          </div>
        </form>
      </div>
    );
  }, [navItems, authSelector]);
  if (
    authSelector.role !== ROLES.SUPERADMIN &&
    authSelector?.is_active_plan &&
    remainingDays < 0
  ) {
    if (authSelector?.is_active_plan) {
      const newDate = moment().subtract(1, 'months').format('YYYY-MM-DD');
      let updatedData = {
        ...authSelector,
        is_active_plan: false,
        plan_end_date: newDate,
      };
      dispatch(addAuthData(updatedData));
    }
  }
  return (
    <Navbar
      as="header"
      expand="md"
      className="d-none d-lg-flex d-print-none shadow sticky-top"
    >
      <Container fluid>
        {!authSelector?.is_active_plan &&
          (authSelector?.role === ROLES.COMPANY ||
            authSelector?.role === ROLES.OWNER) && (
            <NavbarBrand
              as={'h1'}
              className="navbar-brand-autodark d-none-navbar-horizontal pe-0 py-0 pe-md-3"
            >
              <Link to={'/'}>
                <img src={Logo} className="navbar-brand-image" alt="Logo" />
              </Link>
            </NavbarBrand>
          )}
        {authSelector?.company_name && (
          <h2 className="mb-0 ps-2">{authSelector.company_name}</h2>
        )}
        {/* <Navbar.Toggle /> */}
        <Nav className="flex-row order-md-last">
          <Nav className="d-none d-md-flex">
            {authSelector.role !== ROLES.SUPERADMIN &&
              authSelector.role !== ROLES.MANAGER &&
              authSelector?.is_active_plan && (
                <div className="nav-item d-none d-md-flex me-3">
                  <div className="btn-list">
                    <Link
                      to={'/plan-details'}
                      className={`btn ${remainingDays < 0 ? 'btn-danger' : 'btn-success'}`}
                    >
                      {remainingDays < 0
                        ? 'Plan Expired'
                        : `${remainingDays} Day Left`}{' '}
                    </Link>
                  </div>
                </div>
              )}
            <NotificationsDropdown />
            {/* {!isMobile && <NotificationsDropdown />} */}
            <ChangeRoleDropdown />
            <HeaderDropdown />
          </Nav>
        </Nav>
        <Navbar.Collapse></Navbar.Collapse>

        {profileModal && (
          <MyProfile
            profileModal={profileModal}
            setProfileModal={setProfileModal}
          />
        )}
      </Container>
    </Navbar>
  );
};
export default SiteHeader;
