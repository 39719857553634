import React, { Fragment, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';

import Loader from '../components/Loader';
import Toaster from '../components/Toaster/Toaster';

import InjectProtectedNode from './injectProtectedNode';
import UnauthorizerNode from './unauthorizerNode';
import { resetAuthData } from '../store/slices/authUser/authUserSlice';
import { showUpdatedToasterMessage } from '../store/slices/toaster/toasterslice';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/tabler.css';
import '../assets/css/custom.css';

const Layout = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authSelector = useSelector((state) => state.rc.authUserReducer);
  axios.interceptors.request.use(
    (req) => {
      req.baseURL = process.env.REACT_APP_API_URL;
      if (authSelector?.token) {
        req.headers = {
          'Content-Type': 'application/json',
          type: 'web',
          fcm_token: authSelector.fcm_token,
          Authorization: `Bearer ${authSelector.token}`,
          ...req.headers,
        };
      }
      return req;
    },
    (error) => {
      return error;
    },
  );
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //catches if the session ended!
      if (
        error.response.status === 401 &&
        error?.response?.data?.message !== 'Email or Password is invalid'
      ) {
        dispatch(
          showUpdatedToasterMessage({
            // message: error.response.statusText,
            message: 'Your session got expired',
            type: 'danger',
          }),
        );
        dispatch(resetAuthData());
        navigate('/login');
      }

      return Promise.reject(error);
    },
  );

  return (
    <Fragment>
      {authSelector.auth ? (
        <InjectProtectedNode authSelector={authSelector} />
      ) : (
        <Suspense fallback={<Loader />}>
          <UnauthorizerNode />
        </Suspense>
      )}
      <Toaster />
      <ToastContainer pauseOnFocusLoss draggable={false} />
    </Fragment>
  );
});

export default Layout;
