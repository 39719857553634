import React, { lazy } from 'react';
import { IconFileInvoice, IconLogout, IconUser } from '@tabler/icons-react';
import {
  ClipboardText,
  Element4,
  EmptyWallet,
  House,
  Key,
  MoneySend,
  Profile2User,
  ReceiptMinus,
  Setting2,
  TaskSquare,
  UserEdit,
  UserSearch,
  UserTag,
  UserTick,
} from 'iconsax-react';
export const ROLES = {
  SUPERADMIN: 'superadmin',
  COMPANY: 'company',
  MANAGER: 'manager',
  OWNER: 'owner',
  TENANT: 'tenant',
  VENDOR: 'vendor',
  GUEST: 'guest',
};
export const BUILDING_TYPE = {
  condominium: 'Condominium',
  bungalow: 'Bungalow',
  two_story_building: 'Two Story Building',
  bilevel_house: 'Bi-level',
  multilevel: 'Multilevel',
};
const Terms = lazy(() => import('../page/CMS/Terms'));
const PrivacyPolicy = lazy(() => import('../page/CMS/PrivacyPolicy'));
const AccountDelete = lazy(() => import('../page/CMS/AccountDelete'));
export const COMMON_ROUTE = [
  {
    path: 'terms-and-conditions',
    element: <Terms />,
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: 'account/delete',
    element: <AccountDelete />,
  },
];
export const NAV_ITEMS = [
  {
    name: 'Dashboard',
    slug: 'dashboard',
    to: '/dashboard',
    icon: <Element4 size={20} />,
  },
  {
    name: 'Companies',
    slug: 'companies',
    to: '/companies',
    icon: <Setting2 size={20} />,
  },
  {
    name: 'Buildings',
    slug: 'buildings',
    to: '/buildings',
    icon: <House size={20} />,
  },
  {
    name: 'Managers',
    slug: 'managers',
    to: '/managers',
    icon: <Profile2User size={20} />,
  },
  {
    name: 'Owners',
    slug: 'owners',
    to: '/owners',
    icon: <Key size={20} />,
  },
  {
    name: 'Tenants',
    slug: 'tenants',
    to: '/tenants',
    icon: <UserSearch size={20} />,
    submenu: [
      {
        name: 'Approved Tenants',
        to: '/tenants',
        icon: '',
      },
      {
        name: 'Tenancies',
        to: '/tenancies',
        icon: '',
      },
      {
        name: 'L4L Applications',
        to: '/tenancy',
        icon: '',
      },
    ],
  },
  {
    name: 'Complaints',
    slug: 'complaints',
    to: '/complaints',
    icon: <ReceiptMinus size={20} />,
  },
  {
    name: 'Tasks',
    slug: 'tasks',
    to: '/tasks',
    icon: <TaskSquare size={20} />,
  },
  {
    name: 'Payments',
    slug: 'transactions',
    to: '/transactions',
    icon: <EmptyWallet size={20} />,
    submenu: [
      {
        name: 'Rent',
        to: '/transactions/rent',
        icon: '',
      },
      {
        name: 'Maintenance',
        to: '/transactions/maintenance',
        icon: '',
      },
      {
        name: 'Task',
        to: '/transactions/task',
        icon: '',
      },
      {
        name: 'Subscriptions',
        to: '/transactions/subscriptions',
        icon: '',
      },
    ],
  },
  {
    name: 'Disbursements',
    slug: 'disbursements',
    to: '/disbursements',
    icon: <MoneySend size={20} />,
  },
  {
    name: 'Vendors',
    slug: 'vendors',
    to: '/vendors',
    icon: <UserTick size={20} />,
  },
  {
    name: 'Guests',
    slug: 'guests',
    to: '/guests',
    icon: <UserTag size={20} />,
  },
  {
    name: 'Feedbacks',
    slug: 'feedbacks',
    to: '/feedbacks',
    icon: <ClipboardText size={20} />,
  },
  {
    name: 'Roles & Permissions',
    slug: 'roles-and-permissions',
    to: '/roles-and-permissions',
    icon: <UserEdit size={20} />,
  },
];

export const ACCOUNT_DROPDOWN_ITEMS = [
  {
    icon: <IconUser className="icon dropdown-item-icon me-2" />,
    value: 'My Profile',
    to: '',
  },
  {
    icon: <IconFileInvoice className="icon dropdown-item-icon me-2" />,
    value: 'Plan Details',
    to: '/plan-details',
  },
  {
    icon: <IconLogout className="icon dropdown-item-icon me-2" />,
    value: 'Logout',
    to: '/logout',
  },
];

export const PAGE_LENGTH = [10, 25, 50, 100];

export const DEFAULT_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: 'Are you sure?',
  description:
    "Do you really want to remove this data? what you've done can't be undone.",
  actionBtnText: 'Delete',
  action: 'DELETE',
};
export const DEFAULT_VERIFICATION_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: 'Are you sure?',
  description: 'You want to send Re-Verification email?',
  actionBtnText: 'Yes, send',
  action: 'SEND_VERIFICATION_EMAIL',
};
export const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
];
export const SERVICES_TYPE = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Hourly', value: 'hourly' },
];
export const USER_STATUS = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];
export const FILTER_STATUS = [
  // { label: "All", value: 2 },
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 0 },
];
export const FILTER_VENDOR_TYPE = [
  // { label: "All", value: 2 },
  { label: 'All', value: 'all' },
  { label: 'Internal', value: 'internal' },
  { label: 'External', value: 'external' },
];
export const SLOT_DURATION = 60;

export const TASK_STATUS = {
  RESCHEDULED: 'rescheduled',
  CANCELLED: 'cancelled',
  WAITING: 'waiting',
  APPROVED: 'approved',
  STARTED: 'started',
  PAUSED: 'paused',
  RESUMED: 'resumed',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
  PAYMENT_IN_PROCESS: 'payment_in_process',
};
export const SOCKET_EVENT = {
  JOIN_ROOM: 'joinRoom',
  MESSAGE_SENT: 'message_sent',
  MESSAGE_RECEIVED: 'message_receive',
  SOCKET_ERROR: 'error',
};

export const CONVERSATION_TYPE = {
  COMPLAINT: 'complaint',
  TASK: 'task',
};

export const MESSAGE_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO: 'video',
  LOCATION: 'location',
  CONTACT: 'contact',
  DOCUMENT: 'document',
  ANNOUNCEMENT: 'announcement',
};
export const NOTIFICATIONS_TYPES = {
  ALL: 'ALL',
  NEW_TENANCY: 'NEW_TENANCY',
  TENANCY_STATUS_CHANGE: 'TENANCY_STATUS_CHANGE',
  ADD_NEW_COMPLAINT: 'ADD_NEW_COMPLAINT',
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  COMPLAINT_STATUS_CHANGE: 'COMPLAINT_STATUS_CHANGE',
  VENDOR_ASSIGNED: 'VENDOR_ASSIGNED',
  MAINTENANCE_REMINDER: 'MAINTENANCE_REMINDER',
  RENT_REMINDER: 'RENT_REMINDER',
  VENDOR_STATUS_CHANGE: 'VENDOR_STATUS_CHANGE',
  TASK_ASSIGNED: 'TASK_ASSIGNED',
  TASK_STATUS_CHANGE: 'TASK_STATUS_CHANGE',
  TASK_STARTED: 'TASK_STARTED',
  TASK_COMPLETED: 'TASK_COMPLETED',
  TASK_INVOICE: 'TASK_INVOICE',
  TASK_PAYMENT_STATUS: 'TASK_PAYMENT_STATUS',
  INVOICE_RAISED: 'INVOICE_RAISED',
};
export const DEFAULT_FORM_CONFIRM_MODAL = {
  show: false,
  isLoading: false,
  title: 'Are you sure?',
  description: 'Do you really want to submit?',
  actionBtnText: 'Yes',
  action: 'YES',
};
const LANDING_URL = process.env.REACT_APP_LANDING_URL;
export const LANDING_REDIRECT_URL = {
  LANDING: `${LANDING_URL}`,
  PRIVACY: `${LANDING_URL}/privacy`,
  TERMS: `${LANDING_URL}/terms-conditions`,
  LANDING_PMC: `${LANDING_URL}/products/property-management-solutions`,
  LANDING_VENDOR: `${LANDING_URL}/products/vendor-management-solutions`,
};
export const CONTACT_EMAIL = 'info@residentconnect.com';
